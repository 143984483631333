<template><border-box id="box4">
    <div class="panel">
        <div class="header">
            实时运行数据
        </div>
        <div class="table">
            <div class="cell">
                <div>当前服务模式</div>
                <div> <span v-if="monitorPageDisplay.modeStatus.value == 0">正常</span>
                    <span v-else-if="monitorPageDisplay.modeStatus.value  == 1">故障</span>
                    <span v-else-if="monitorPageDisplay.modeStatus.value  == 2">平层关人</span>
                    <span v-else-if="monitorPageDisplay.modeStatus.value  == 3">非平层关人</span>
                    <span v-else>--</span></div>
            </div>
            <div class="cell">
                <div>轿厢运行方向</div>
                <div>
                    {{ monitorPageDisplay.direction.value == 0 ? '停留' : monitorPageDisplay.direction.value == 1 ? '上行' : monitorPageDisplay.direction.value == 2 ? '下行' : '--' }}
                </div>
            </div>
            <div class="cell">
                <div>平层状态</div>
                <div>
                    {{ monitorPageDisplay.floorStatus.value == 1 ? '非平层' : monitorPageDisplay.floorStatus.value == 0 ? '平层' : '--' }}
                </div>
            </div>
            <div class="cell">
                <div>厅门状态</div>
                <div>
                    {{ monitorPageDisplay.doorStatus.value == 1 ? '锁止' : monitorPageDisplay.doorStatus.value === 0 ? '非锁止' : '--' }}
                </div>
            </div>
            <div class="cell">
                <div>当前速度</div>
                <div>{{monitorPageDisplay.speed.value}}m/s</div>
            </div>
            <div class="cell">
                <div>安全回路状态</div>
                <div>
                    {{ monitorPageDisplay.safeLoop.value == 1 ? '断开' : monitorPageDisplay.safeLoop.value == 0 ? '正常' : '--' }}
                </div>
            </div>
            <div class="cell">
                <div>轿厢运行状态</div>
                <div> {{ monitorPageDisplay.runStatus.value == 1 ? '运行' : monitorPageDisplay.runStatus.value == 0 ? '停止' : '--' }}</div>
            </div>
            <div class="cell">
                <div>电梯当前楼层</div>
                <div>{{monitorPageDisplay.floor.value}}</div>
            </div>
            <div class="cell">
                <div>关门到位</div>
                <div>
                    {{ monitorPageDisplay.droopClose.value == 1 ? '关门到位' : monitorPageDisplay.droopClose.value == 0 ? '无关门到位' : '--' }}
                </div>
            </div>
            <div class="cell">
                <div>轿门状态</div>
                <div>
                    {{ monitorPageDisplay.carStatus.value == 1 ? '非锁止' : monitorPageDisplay.carStatus.value == 0 ? '锁止' : '--' }}
                </div>
            </div>
            <div class="cell">
                <div>机房温度</div>
                <div>{{monitorPageDisplay.temperature.value}}℃</div>
            </div>
            <div class="cell">
                <div>曳引机状态-制动器</div>
                <div>
                    {{ monitorPageDisplay.driveStatus.value == 1 ? '释放' : monitorPageDisplay.driveStatus.value == 0 ? '提起' : '--' }}
                </div>
            </div>
           <!--  <div class="cell">
                <div>较厢内是否有人</div>
                <div>
                    {{ monitorPageDisplay.hasPeople.value == 1 ? '有人' : monitorPageDisplay.hasPeople.value == 0 ? '无人' : '--' }}
                </div>
            </div> -->
        </div>
    </div> </border-box>
</template>
<script>import BorderBox from '../../../components/BorderBox'
import Monitor from '@/pages/monitor/monitor'
export default {
    data() {
        return {
            elevatorCode: null,
        }
    },
    mixins: [Monitor],
    mounted() {}, components: {
    BorderBox,
  },
    methods: {
        getElevatorCode() {
            return this.elevatorCode
        },
        updateByInfo(info) {
            this.elevatorCode = info ? info.v_elevator_code : null
            if (info) {
                this.initSocket(info.v_elevator_code, ['CarRoof', 'MontorRoom', 'SINGLEBOX'])
            } else {
                this.closeConnection()
            }
        },
    }
}
</script>
<style lang="scss" scoped>
@import "@/common/styles/handle.scss";
#box4{
    height: 100%;
}
.panel {
    display: flex;
    flex-direction: column;
    height: 100%;
    // border:1px solid rgba(255,255,255,1);
    @include border_color(panelBorderColor);
    @include background_color(panelBgColor);
}

.header {
    line-height: 45px;
    height: 45px;
    font-size: 16px;
    @include font_color(singleMonitorTableHeaderTextColor);
    padding: 0 20px;
    font-weight: 600;
}

.table {
    flex: 1;
    @include background_color(singleMonitorTableBgColor);
    @include font_color(singleMonitorTableTextColor);
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    //gap: 18px;
    justify-content: space-around;

    .cell {
        display: flex;
        justify-content: space-between;

        div {
            font-size: 16px;
        }

        div:first-of-type {
            width: 60%;
            font-size: 16px;
            font-weight: 600;
        }

        div:last-of-type {
            width: 40%;
        }
    }
}
.table .cell div[data-v-6b79d6ec]:first-of-type{
    font-size: 16px;
}
</style>