<template>
  <div class="border-box">
    <div class="wrapper">
      <div class="inner">
        <slot />
      </div>
    </div>
    <img src="../assets/tonghong/border_lt.png" class="corner corner-lt">
    <img src="../assets/tonghong/border_rt.png" class="corner corner-rt">
    <img src="../assets/tonghong/border_rb.png" class="corner corner-rb">
    <img src="../assets/tonghong/border_lb.png" class="corner corner-lb">
  </div>
</template>

<script>
export default {
  name: 'BorderBox'
}
</script>

<style lang="scss" scoped>
.border-box {
  position: relative;
  min-width: 100px;
  padding: 6px 0 5px 3px;
  & > .wrapper {
    width: 100%;
    height: 100%;
    border: 1px solid #1c52a7;
    background-color: #0d1d4d;
    .inner {
      position: relative;
      z-index: 2;
      padding:0 8px 8px 0;
      // min-height: 80px;
      height: 100%;
      color: #fff;
      overflow: hidden;
    }
  }
  & > .corner {
    position: absolute;
    z-index: 1;
    &.corner-lt {
      top: 0;
      left: 0;
    }
    &.corner-rt {
      top: 6px;
      right: 0;
    }
    &.corner-rb {
      right: 0;
      bottom: 5px;
    }
    &.corner-lb {
      bottom: 0;
      left: 0;
    }
  }
}
</style>
