<template>
    <div style="height:100%;">
        <SideLayout ref="sideRef" :minLeftWidth="expand ? 0 : 240" :minRightWidth="1066" @onExpand="onLayoutExpand">
            <template v-slot:left-content>
                <div class="left-content-wrapper">
                    <ElevatorRadioSelector ref="radioSelRef" @didSelectElevator="onSelectElevator" @didSelectVillage="onSelectVillage" />
                </div>
            </template>
            <template v-slot:right-content>
                <div class="right-content-wrapper">
                    <div class="monitor-left">
                        <div class="ml-top">
                            <div class="header">{{checkedVillage?checkedVillage.v_village_name:'--'}}</div>
                            <div class="video-wrapper">
                                <ElevatorVideo :showMonitor="true" :elevator="checkedElevator" :num="0" />
                            </div>
                        </div>
                        <div class="ml-bottom">
                            <div class="mlb-left">
                                <ElevatorInfo ref="infoRef" />
                            </div>
                            <div class="mlb-right">
                                <ElevatorMaintenance ref="maintenaceRef" />
                                <!--  <div class="mlbr-top">
                                    <ElevatorFault ref="faultRef" @relay="onReplayBy"/>
                                </div>
                                <div class="mlbr-bottom">
                                    <ElevatorRescue ref="sleepyRef" @relay="onReplayBy" />
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="monitor-right">
                        <div style="display:flex; flex-direction: column; gap:10px; flex: 3;">
                            <div style="flex:3;">
                                <ElevatorMonitor ref="monitorRef" />
                            </div>
                            <div style="flex:1;">
                                <ElevatorFault ref="faultRef" @relay="onReplayBy" />
                            </div>
                        </div>
                        <div style="display:flex; flex-direction: column; gap:10px; flex:1">
                            <div style="flex:3;">
                                <ElevatorRescue ref="sleepyRef" @relay="onReplayBy" />
                            </div>
                            <div style="flex:1;">
                                <ElevatorHeatMap ref="heatMapRef" />
                                <!-- <ElevatorMaintenance ref="maintenaceRef" /> -->
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </SideLayout>
        <Modal footer-hide :mask="true" :mask-closable="false" :scrollable="true" class-name="vertical-center-modal" title="详情" v-model="modaDetail" width="1200">
            <div style="background:#fff">
                <Row :gutter="10">
                    <Col :lg="12" :md="12" :sm="12" :xs="12" class="flexVideo">
                    <Tabs size="small">
                        <TabPane label="视频" class="TimeDiv">
                            <video :src="videoUrl" controls ref="video" v-if="videoUrl != '' && videoUrl != null">
                                您的浏览器不支持video元素。
                            </video>
                            <!-- <svg-icon :icon-class="'notViodeo'" class="svg-icon_not_video" v-if="!videoUrl" /> -->
                            <br />
                            <font style="margin-left:205px" v-if="!videoUrl">暂无视频</font>
                        </TabPane>
                        <TabPane label="图片">
                            <Scroll v-if="faultImageList.length !== 0">
                                <div :key="index" dis-hover style="margin: 32px 0" v-for="(item, index) in faultImageList">
                                    <img :src="item" style="max-width:100%" />
                                </div>
                            </Scroll>
                            <!-- <svg-icon :icon-class="'notImgList'" class="svg-icon_not_video" v-if="faultImageList.length === 0" /> -->
                            <br />
                            <font style="margin-left:210px" v-if="faultImageList.length === 0">暂无图片</font>
                        </TabPane>
                    </Tabs>
                    </Col>
                    <Col :lg="6" :md="6" :sm="6" :xs="6" v-if="realDataShow">
                    <!-- <div>123</div> -->
                    <div class="live-progress mt16" style="position: relative">
                        <h2>困人救援</h2>
                        <div class="item" v-for="(item, index) in realData" :key="index">
                            <div class="box">
                                <h5 class="title" style="
                       overflow: hidden;
                       white-space: nowrap;
                       text-overflow: ellipsis;
                  ">
                                    {{ item.comment }}
                                </h5>
                                <div class="text">
                                    <span>{{ item.status_time }}</span>
                                    <span style="
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      max-width: 55px;
                    ">{{ item.handler }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    </Col>
                    <Col :lg="realDataShow?6:12" :md="realDataShow?6:12" :sm="realDataShow?6:12" :xs="realDataShow?6:12">
                    <CellGroup>
                        <Cell :extra="
                  faultInfoDetail ? faultInfoDetail.v_fault_id + '' : '--'
                " title="故障ID" />
                        <Cell :extra="
                  faultInfoDetail ? faultInfoDetail.v_elevator_name + '' : '--'
                " title="电梯编号" />
                        <Cell :extra="
                  faultInfoDetail.v_address
                    ? faultInfoDetail.v_address + ''
                    : '--'
                " title="电梯地址" />
                        <Cell :extra="
                  faultInfoDetail.v_elevator_type
                    ? faultInfoDetail.v_elevator_type + ''
                    : '--'
                " title="电梯类型" />
                        <Cell :extra="
                  faultInfoDetail.dt_report_time
                    ? faultInfoDetail.dt_report_time + ''
                    : '--'
                " title="上报时间" />
                        <Cell :extra="
                  faultInfoDetail.dt_end_time
                    ? faultInfoDetail.dt_end_time + ''
                    : '--'
                " title="结束时间" />
                        <Cell :extra="
                  faultInfoDetail
                    ? faultInfoDetail.i_fault_type +
                      '-' +
                      faultInfoDetail.v_fault_name +
                      ''
                    : '--'
                " title="故障类型" />
                        <Cell :extra="
                  faultInfoDetail.v_fault_second_type
                    ? faultInfoDetail.v_fault_second_type +
                      '-' +
                      faultInfoDetail.v_fault_second_name +
                      ''
                    : '--'
                " title="故障子类型" />
                        <Cell :extra="
                  faultInfoDetail ? faultInfoDetail.i_fault_num + '' : '--'
                " title="上报次数" />
                        <Cell :extra="
                  faultInfoDetail
                    ? faultInfoDetail.i_mode_status_name + ''
                    : '--'
                " title="所处服务模式" />
                        <Cell :extra="
                  faultInfoDetail.i_floor ? faultInfoDetail.i_floor + '' : '--'
                " title="故障时梯楼层" />
                        <Cell :extra="
                  faultInfoDetail ? faultInfoDetail.i_status_name + '' : '--'
                " selected title="当前故障状态" />
                    </CellGroup>
                    </Col>
                </Row>
            </div>
        </Modal>
    </div>
</template>
<script>
import SideLayout from '@/components/sideLayout'

import ElevatorRadioSelector from '../components/elevatorRadioSelector'
import ElevatorVideo from '../components/elevatorVideo'
import ElevatorFault from '../components/elevatorFault'
import ElevatorRescue from '../components/elevatorRescue'
import ElevatorInfo from '../components/elevatorInfo'
import ElevatorMonitor from '../components/elevatorMonitor'
import ElevatorMaintenance from '../components/elevatorMaintenance'
import ElevatorHeatMap from '../components/elevatorHeatMap'
import { getElevatorInfo, getElevatorHlsInfo } from '@/api/elevator/index'

import {
    queryFaultList,
    getFaultDetail,
    getElevatorFaultRealTimeByEvent
} from '@/api/repair/index.js'
export default {
    data() {
        return {
            expand: false,
            checkedElevator: '',
            checkedVillage: null,
            infoRef: null,
            faultRef: null,
            sleepyRef: null,
            videoUrl: null,

            realDataShow: false,
            modaDetail: false,
            realData: [],
            faultInfoDetail: '',
            videoUrl: '',
            faultImageList: '',

            faultListQuery: {
                iUncivilizedBehaviorFlag: 0,
                villageId: '',
                buildingId: '',
                vElevatorCode: '',
                dtReportTime: this.formatDate(-30) + '',
                dtEndTime: this.formatDate(0) + ' 23:59:59',
                overdue: '',
                pageIndex: 1,
                pageSize: 100,
            },
            sleepyListQuery: {
                iUncivilizedBehaviorFlag: 0,
                villageId: '',
                buildingId: '',
                vElevatorCode: '',
                dtReportTime: this.formatDate(-30) + '',
                dtEndTime: this.formatDate(0) + ' 23:59:59',
                overdue: '',
                pageIndex: 1,
                pageSize: 10,
                iFaultType:'7,8'
            },
        }
    },
    components: {
        SideLayout,
        ElevatorFault,
        ElevatorRescue,
        ElevatorInfo,
        ElevatorMonitor,
        ElevatorMaintenance,
        ElevatorHeatMap,
        ElevatorVideo,
        ElevatorRadioSelector
    },
    mounted() {
        this.$refs.sideRef.updateExpand()
        let query = this.$route.query
        if (query.villageId && query.elevatorCode && query.registerNum && query.elevatorId) {
            this.$refs.radioSelRef.setDefaultSelect(query.villageId, query.elevatorCode)
            let elevator = {
                v_elevator_id: query.elevatorId,
                v_elevator_code: query.elevatorCode,
                register_number: query.registerNum,
            }

            this.onSelectElevator(elevator)
            if (query.eventId && query.faultId)
                this.replay(query.faultId, query.eventId)
        }

    },

    created() {
        // console.log(this.$route,'12312');
    },

    methods: {
        getInfo(elevator) {
            getElevatorInfo({
                register_number: elevator.register_number,
            }).then((res) => {
                this.$refs.infoRef.updateByInfo(res.info)
                this.$refs.maintenaceRef.updateByInfo(res.info)
                this.$refs.heatMapRef.updateByInfo(res.info)
            })
        },
        getRecords(elevator) {
            this.faultListQuery.vElevatorCode = elevator.v_elevator_code
            queryFaultList(this.faultListQuery).then((res) => {
                let arr = res.info ? res.info.list : []
                this.$refs.faultRef.updateByInfo(arr.filter((item)=> {
                    return item.i_fault_type !=7 && item.i_fault_type !=8
                }))
                this.$refs.sleepyRef.updateByInfo(arr.filter((item)=> {
                    return item.i_fault_type ==7 || item.i_fault_type ==8
                }))
            })
            queryFaultList(this.sleepyListQuery).then((res) => {
                this.$refs.sleepyRef.updateByInfo(res.info ? res.info.list : [])
                // this.$refs.sleepyRef.updateByInfo(res.info ? res.info.elevatorHlsInfoSleepy : [])
            })
        },
        getFaultDetail(faultId) {
            getFaultDetail(faultId).then(res => {
                if (res.code === '0000') {
                    this.videoUrl = res.info.videoUrl
                }
            })
        },
        replay(faultId, eventId) {
            getFaultDetail(faultId).then(res => {
                if (res.code === '0000') {
                    this.faultInfoDetail = res.info.detail
                    this.videoUrl = res.info.videoUrl
                    this.faultImageList = res.info.imageList
                }
            })
            if (eventId) {
                getElevatorFaultRealTimeByEvent(eventId).then(res => {
                    this.realData = res.info
                })
            }

            this.realDataShow = eventId ? true : false
            this.modaDetail = true
        },
        onReplayBy(data) {
            this.replay(data.faultId, data.eventId)
        },
        onSelectElevator(elevator) {
            this.getInfo(elevator)
            this.getRecords(elevator)
            this.$refs.monitorRef.updateByInfo(elevator)
            this.checkedElevator = elevator
        },
        onSelectVillage(village) {
            this.checkedVillage = village
        },
        onTapExpand() {
            this.$refs.sideRef.updateExpand()
        },
        onLayoutExpand(expand) {
            this.expand = expand
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/common/styles/handle.scss";

.left-content-wrapper {
    height: 100%;
    position: relative;
}

.right-content-wrapper {
    position: relative;
    margin: 10px;
    height: calc(100% - 20px);
    display: flex;
    min-height: 800px;
}

.header {
    line-height: 45px;
    height: 45px;
    font-size: 16px;
    @include font_color(tablePageTitleTextColor);
    padding: 0 20px;
    font-weight: 600;
    @include background_color(singleMonitorTableHeaderBgColor);
}

.video-wrapper {
    background-color: #383C4B;
    width: 100%;
    height: calc(100% - 45px);
}

.monitor-left {
    width: calc((100% - 10px)/ 10 * 7);
}

.monitor-right {
    width: calc((100% - 10px)/ 10 * 3);
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
}

.ml-top {
    height: calc((100% - 10px)/ 4 * 3);
    border: 1px solid;
    @include border_color(panelBorderColor);
}

.ml-bottom {
    margin-top: 10px;
    height: calc((100% - 10px)/ 4 * 1);
    display: flex;
    gap: 10px;
}

.mlb-left {
    flex: 100;
}

.mlb-right {
    flex: 46;
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.mlbr-top {
    flex: 1;
    background-color: #fff;
}


.mlbr-bottom {
    flex: 1;
    background-color: #fff;
}

.mr-top {
    flex: 2;
}

.mr-bottom {
    flex: 2;
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.mrb-top {
    flex: 7;
    display: flex;
    flex-direction: column;
}

.mrb-bottom {
    flex: 1;
}

video {
    width: 100%;
    height: 500px;
}

.live-progress {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 18px;

    h2 {
        margin: 16px;
    }

    &>.item {
        flex: 1;
        position: relative;
        margin-bottom: 15px;

        .box {
            display: flex;
            flex-direction: column;
            position: relative;
            height: 50px;
            border: 1px solid rgb(134, 131, 131);
            border-radius: 25px;
            align-items: center;
            justify-content: center;

            &>div {
                width: 100%;
            }
        }

        .title {
            font-size: 18px;
            color: #515A6e;
        }

        .text {
            display: flex;
            width: 100%;
            padding: 0 10px;
            margin-top: 4px;
            font-size: 14px;
            color: #515A6e;
            justify-content: space-around;
        }

        &.active {
            background-image: linear-gradient(to right, #00ffff, #00aff8);

            .title,
            .text {
                color: #0d2f6a;
            }
        }

        &:after {
            position: absolute;
            transform: translateX(-50%);
            width: 1px;
            background-color: #000;
            content: '';
            left: 50%;
            top: 50px;
            height: 15px;
        }

        &:last-child:after {
            background-color: transparent;
        }
    }
}
</style>