<template>
  <border-box id="box4">
    <div class="panel">
      <div class="header">基本信息</div>
      <div class="container">
        <div class="table0">
          <div class="cell">
            <div class="label">当前故障</div>
            <div class="value">
              {{ response.fault_name ? response.fault_name : '--' }}
            </div>
          </div>
          <div class="cell">
            <div class="label">品牌</div>
            <div class="value">
              {{
                response.v_elevator_brand_name
                  ? response.v_elevator_brand_name
                  : '--'
              }}
            </div>
          </div>
          <div class="cell">
            <div class="label">注册代码</div>
            <div class="value">
              {{ response.v_equipment_code ? response.v_equipment_code : '--' }}
            </div>
          </div>
          <div class="cell">
            <div class="label">电梯类型</div>
            <div class="value">
              {{
                response.elevator_type_name ? response.elevator_type_name : '--'
              }}
            </div>
          </div>
          <div class="cell">
            <div class="label">上次维保日期</div>
            <div class="value">
              {{
                response.last_maintain_date ? response.last_maintain_date : '--'
              }}
            </div>
          </div>
          <div class="cell">
            <div class="label">下次年检时间</div>
            <div class="value">
              {{
                response.next_inspect_date ? response.next_inspect_date : '--'
              }}
            </div>
          </div>
        </div>
        <div class="table">
          <div class="cell-header">电梯运行统计（累计）</div>
          <div class="cell">
            <div class="flex-center">运行次数</div>
            <div class="flex-center">
              <span class="num">{{
                response.bi_run_count ? response.bi_run_count : '--'
              }}</span
              >&nbsp;<span class="unit">次</span>
            </div>
          </div>
          <div class="cell">
            <div class="flex-center">运行里程</div>
            <div class="flex-center">
              <span class="num">{{
                response.bi_run_distance_count
                  ? parseFloat(response.bi_run_distance_count / 1000).toFixed(2)
                  : '--'
              }}</span>
              &nbsp;<span class="unit">km</span>
            </div>
          </div>
          <div class="cell">
            <div class="flex-center">开关门次数</div>
            <div class="flex-center">
              <span class="num">{{
                response.bi_door_count ? response.bi_door_count : '--'
              }}</span
              >&nbsp;<span class="unit">次</span>
            </div>
          </div>
          <div class="cell">
            <div class="flex-center">折弯次数</div>
            <div class="flex-center">
              <span class="num">{{
                response.bi_bend_count ? response.bi_bend_count : '--'
              }}</span
              >&nbsp;<span class="unit">次</span>
            </div>
          </div>
        </div>
        <div class="table">
          <div class="cell-header">电梯运行统计（日均）</div>
          <div class="cell">
            <div class="flex-center">运行次数</div>
            <div class="flex-center">
              <span class="num">{{
                response.bi_run_count
                  ? calcAvgValue(
                      response.bi_run_count,
                      response.cumulative_days
                    )
                  : '--'
              }}</span
              >&nbsp;<span class="unit">次</span>
            </div>
          </div>
          <div class="cell">
            <div class="flex-center">运行里程</div>
            <div class="flex-center">
              <span class="num">{{
                response.bi_run_distance_count
                  ? parseFloat(
                      calcAvgValue(
                        response.bi_run_distance_count,
                        response.cumulative_days
                      ) / 1000
                    ).toFixed(2)
                  : '--'
              }}</span>
              &nbsp;<span class="unit">km</span>
            </div>
          </div>
          <div class="cell">
            <div class="flex-center">开关门次数</div>
            <div class="flex-center">
              <span class="num">{{
                response.bi_door_count
                  ? calcAvgValue(
                      response.bi_door_count,
                      response.cumulative_days
                    )
                  : '--'
              }}</span
              >&nbsp;<span class="unit">次</span>
            </div>
          </div>
          <div class="cell">
            <div class="flex-center">折弯次数</div>
            <div class="flex-center">
              <span class="num">{{
                response.bi_bend_count
                  ? calcAvgValue(
                      response.bi_bend_count,
                      response.cumulative_days
                    )
                  : '--'
              }}</span
              >&nbsp;<span class="unit">次</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </border-box>
</template>
<script>
import BorderBox from '../../../components/BorderBox'

export default {
  data() {
    return {
      params: {
        elevatorCode: '',
        elevatorId: '',
      },
      response: {},
    }
  },
  components: {
    BorderBox,
  },
  mounted() {},
  methods: {
    getInfoBy(elevatorCode, registorNum) {
      //TO-DO
    },
    updateByInfo(info) {
      this.response = info
    },
    calcAvgValue(total, period) {
      return period > 0 ? parseInt(total / period) : 0
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/common/styles/handle.scss';
#box4{
    height: 100%;
}
.panel {
  display: flex;
  flex-direction: column;
  height: 100%;
//   border: 1px solid rgba(255, 255, 255, 1);
  @include border_color(panelBorderColor);
  @include background_color(panelBgColor);
}

.header {
  line-height: 38px;
  height: 38px;
  font-size: 16px;
  @include font_color(singleMonitorTableHeaderTextColor);
  @include background_color(singleMonitorTableHeaderBgColor);
  padding: 0 20px;
  font-weight: 600;
}

.container {
  display: flex;
  justify-content: space-between;
  flex: 1;
//   padding-bottom: 10px;
  height: calc(100% - 45px);
}

.table0 {
  width: 48%;
  @include background_color(singleMonitorTableBgColor);
  @include font_color(singleMonitorTableTextColor);
  padding: 0px 0px 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //gap: 10px;

  .cell {
    display: flex;
    justify-content: space-between;

    div:first-of-type {
      font-weight: 600;
    }
  }
}

.table {
  width: 26%;
  @include background_color(singleMonitorTableBgColor);
  @include font_color(singleMonitorTableTextColor);
  display: flex;
  flex-direction: column;
  //gap: 10px;
  justify-content: space-between;

  .cell {
    display: flex;
    justify-content: space-between;

    div {
      width: 50%;
    }

    div:first-of-type {
      font-weight: 600;
    }
  }
}

.text-inline {
  display: flex;
  align-items: center;
}

.label {
  width: 110px;
}

.value {
  flex: 1;
}

.num {
  font-weight: 800;
  font-size: 19px;
}

.unit {
  font-size: 12px;
}

.flex-center {
  display: flex;
  align-items: center;
}

.cell-header {
  font-weight: 800;
  color: rgba(253, 240, 126);
  margin-bottom: 10px;
}
</style>