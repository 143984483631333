import http from '@/common/utils/http'

/** 获取电梯详情 */
export function getElevatorInfo(data) {
  return http({
    url: `wuye/getElevatorInfo`,
    method: 'post',
    data
  })
}

/** 获取故障与困人记录 */
export function getElevatorHlsInfo(data) {
  return http({
    url: `RuiJin/getElevatorHlsInfo`,
    method: 'post',
    data
  })
}

export function getElevatorHeatMapStatistics(data) {
    return http({
        url: `elevator/elevatorHeatMapNew`,
        method: 'post',
        data
    })
}

export function getElevatorHeatMapNewStatistics(data) {
    return http({
        url: `wuye/elevatorHeatMapNew`,
        method: 'post',
        data
    })
}

export function getElevatorScreenInfo(data) {
    debugger
    return http({
        url: `elevatorScreen/elevatorInfo/${data.elevatorCode}`,
        method: 'get',
    })
}
