<template><border-box id="box4">
    <div class="panel">
        <div class="fault">
            <div class="tips">
                <div class="round">
                    <div>故障</div>
                    <div>提示</div>
                </div>
            </div>
            <div class="list">
                <el-table ref="singleTable" :header-cell-style="{color:'#484848','font-weight':600}" :data="response" highlight-current-row style="width: 100%">
                    <el-table-column type="index" width="25" > </el-table-column>
                    <el-table-column  width="126" property="dt_report_time" :show-overflow-tooltip="true" label="上报时间">
                    </el-table-column>
                    <el-table-column :show-overflow-tooltip="true" property="v_fault_name" label="故障类型">
                    </el-table-column>
                    <el-table-column :show-overflow-tooltip="true" property="" width="80" label="操作">
                        <template slot-scope="scope">
                            <el-button @click.native.prevent="onReplay(scope.row.v_fault_id,scope.row.event_id)" type="primary" size="mini">
                                回看
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>  </border-box>
</template>
<script>import BorderBox from '../../../components/BorderBox'
export default {
    data() {
        return {
            response: [],
        }
    },   components: {
    BorderBox,
  },
    methods: {
        updateByInfo(info) {
            if (info)
                this.response = info.splice(0, 2)
        },
        onReplay(faultId, eventId) {
            this.$emit('relay', { faultId, eventId })
        },
    }
}
</script>
<style lang="scss" scoped>
@import "@/common/styles/handle.scss";
#box4{
    height: 100%;
}
.panel {
    flex: 1;
    display: flex;
    gap: 10px;
    overflow-y: auto;
    height: 100%;
    // border: 1px solid #fff;
    @include border_color(panelBorderColor);
    @include background_color(panelBgColor);

    .fault {
        flex: 1;
        display: flex;
        @include background_color(faultBgColor);
        overflow: hidden;

        .tips {
            flex: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;


            .round {
                width: 80px;
                height: 80px;
                font-size: 14px;
                font-weight: 700;
                margin: 0 auto;
                text-align: center;
                background-color: red;
                border-radius: 80px;
                color: #fff;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }

        .list {
            flex:8;
            overflow: auto;
        }
    }
}

.el-table {
    ::v-deep .el-table__empty-block {
        @include background_color("tableEmptyBgColor");
    }
}

.el-table ::v-deep.el-table--fit {
    @include background_color(tableBgColor);
}

.el-table {
    ::v-deep .el-table__cell {
        @include font_color("tableCellTextColor");
        @include background_color("tableEmptyBgColor");
    }
}

.el-table--border::after,
::v-deep .el-table--group::after,
.el-table::before {
    @include background_color(panelBorderColor);
}


.el-table ::v-deep th.el-table__cell.is-leaf {
    @include border_color(panelBorderColor);
}

.el-table ::v-deep td.el-table__cell, .el-table th.el-table__cell.is-leaf {
@include border_color(panelBorderColor);
}

::v-deep .el-button span{
    font-size:14px!important;
}
</style>